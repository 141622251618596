$('a[href*="#"]')

    .not('[href="#"]')

    .not('[href="#0"]')

    .click(function (event) {

        if (

            location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')

            &&

            location.hostname == this.hostname

        ) {

            var target = $(this.hash);

            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

            if (target.length) {

                event.preventDefault();

                $('html, body').animate({

                    scrollTop: target.offset().top

                }, 1000, function () {

                    var $target = $(target);

                    $target.focus();

                    if ($target.is(":focus")) {

                        return false;

                    } else {

                        $target.attr('tabindex', '-1');

                        $target.focus();

                    };

                });

            }

        }

    });


function ustawHeader() {
    var a = $(window).scrollTop();
    if (a >= 133) {
        $(".header").addClass("smaller");
    } else {
        $(".header").removeClass("smaller");
    }
}
$(document).ready(ustawHeader);
$(window).scroll(ustawHeader);

$(".menu-button").click(function () {
    if ($('.menu-button').hasClass("opened")) { 
        $(".menu-button").removeClass("opened");
    }
    else {
        $(".menu-button").addClass("opened");
    }
});